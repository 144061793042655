import mixpanel from 'mixpanel-browser'

const returnSessionStorage = () => {
  handleSessionStorage()
  return 'NA' //direct
}

const handleSessionStorage = () => {
  sessionStorage.setItem('cstmMed', 'NA')
}

const sessionStorageFn = () => {
  sessionStorage.setItem('cstmCmpgn', 'NA')
  return 'NA'
}

const cstmCntntStorage = () => {
  sessionStorage.setItem('cstmCntnt', 'NA')
  return 'NA'
}

export const marketingChannelGrouping = (searchParams) => {
  const utmSource = searchParams?.utm_source ? searchParams?.utm_source.toLowerCase() : null
  const utmMedium = searchParams?.utm_medium ? searchParams?.utm_medium.toLowerCase() : null
  const sourcesToCheck = [
    'twitter',
    'tiktok',
    'snapchat',
    'instagram',
    'facebook',
    'instagramstories',
    'youtube',
    'google-youtube',
    'linkedin',
  ]
  const gclid = searchParams?.gclid ? searchParams?.gclid : null

  if (sourcesToCheck?.includes(utmSource)) {
    sessionStorage.setItem('marketingChnnlGrp', 'Paid Social')
    return 'Paid Social'
  } else if (utmSource?.includes('google') || gclid) {
    sessionStorage.setItem('marketingChnnlGrp', 'Paid Search')
    return 'Paid Search'
  } else if (
    !sourcesToCheck?.includes(utmSource) &&
    (document.referrer?.includes('twitter') ||
      document.referrer?.includes('tiktok') ||
      document.referrer?.includes('snapchat') ||
      document.referrer?.includes('instagram') ||
      document.referrer?.includes('facebook') ||
      document.referrer?.includes('google-youtube') ||
      document.referrer?.includes('linkedin'))
  ) {
    sessionStorage.setItem('marketingChnnlGrp', 'Organic Social')
    return 'Organic Social'
  } else if (utmMedium?.includes('display') || utmMedium?.includes('performance')) {
    sessionStorage.setItem('marketingChnnlGrp', 'display')
    return 'display'
  } else if (
    document.referrer.includes('google') ||
    document.referrer.includes('bing') ||
    document.referrer.includes('yahoo')
  ) {
    sessionStorage.setItem('marketingChnnlGrp', 'Organic Search')
    return 'Organic Search'
  }

  if (utmSource?.includes('moengage')) {
    sessionStorage.setItem('marketingChnnlGrp', 'Push Notification')
    return 'Push Notification'
  }
  if (utmSource == null) {
    sessionStorage.setItem('marketingChnnlGrp', 'Direct')
    return 'Direct'
  }
  sessionStorage.setItem('marketingChnnlGrp', 'Referral')
  return 'Referral'
}

export const getCustomSource = (searchParams) => {
  const utmSource = searchParams?.utm_source ? searchParams?.utm_source.toLowerCase() : null
  const gclid = searchParams?.gclid ? searchParams?.gclid : null

  if (
    utmSource &&
    (document.referrer.includes('twitter') ||
      document.referrer.includes('tiktok') ||
      document.referrer.includes('snapchat') ||
      document.referrer.includes('instagram') ||
      document.referrer.includes('facebook') ||
      document.referrer.includes('instagramstories') ||
      document.referrer.includes('youtube') ||
      document.referrer.includes('linkedin'))
  ) {
    sessionStorage.setItem('cstmSrc', utmSource)
    return utmSource //paid social
  } else if (
    !utmSource &&
    (document.referrer.includes('twitter') ||
      document.referrer.includes('tiktok') ||
      document.referrer.includes('snapchat') ||
      document.referrer.includes('instagram') ||
      document.referrer.includes('facebook') ||
      document.referrer.includes('youtube') ||
      document.referrer.includes('linkedin'))
  ) {
    sessionStorage.setItem('cstmSrc', document.referrer)
    return document.referrer //organic social
  } else if (document.referrer.includes('google') || gclid) {
    sessionStorage.setItem('cstmSrc', 'google')
    return 'google'
  } else if (!document.referrer && !utmSource) {
    sessionStorage.setItem('cstmSrc', 'direct')
    return 'direct'
  } else if (document.referrer.includes('google') && utmSource.includes('performance')) {
    sessionStorage.setItem('cstmSrc', 'google-performancemax')
    return 'google-performancemax'
  } else if (document.referrer.includes('google') && utmSource.includes('display')) {
    sessionStorage.setItem('cstmSrc', 'google-display')
    return 'google-display'
  } else if (
    document.referrer &&
    !(
      document.referrer.includes('twitter') ||
      document.referrer.includes('tiktok') ||
      document.referrer.includes('snapchat') ||
      document.referrer.includes('instagram') ||
      document.referrer.includes('youtube') ||
      document.referrer.includes('facebook') ||
      document.referrer.includes('linkedin')
    )
  ) {
    sessionStorage.setItem('cstmSrc', document.referrer)
    return document.referrer
  }
  sessionStorage.setItem('cstmSrc', searchParams?.utm_source)
  return searchParams?.utm_source
}

export const customMedium = (searchParams) => {
  const utmSource = searchParams?.utm_source ? searchParams?.utm_source.toLowerCase() : null

  if (document.referrer.includes('google') && (utmSource == 'google-display' || utmSource == 'google-performancemax')) {
    sessionStorage.setItem('cstmMed', 'display')
    
    return 'display'
  } else if (
    !utmSource &&
    (document.referrer.includes('twitter') || document.referrer.includes('tiktok') || document.referrer.includes('snapchat') || document.referrer.includes('instagram') || document.referrer.includes('facebook') || document.referrer.includes('youtube') || document.referrer.includes('linkedin'))
  ) {
    
    returnSessionStorage()
  } else if (!document.referrer && !utmSource) {
    
    returnSessionStorage()
  } else if (
    document.referrer &&
    !( document.referrer.includes('twitter') || document.referrer.includes('tiktok') || document.referrer.includes('snapchat') || document.referrer.includes('instagram') || document.referrer.includes('facebook') || document.referrer.includes('youtube') || document.referrer.includes('linkedin') )
  ) {
    
    returnSessionStorage()
  }
  sessionStorage.setItem('cstmMed', searchParams?.utm_medium)
  return searchParams?.utm_medium
}

export const getCustomCampaign = (searchParams) => {
  const utmSource = searchParams?.utm_source ? searchParams?.utm_source.toLowerCase() : null

  if (
    document.referrer &&
    !(
      document.referrer.includes('twitter') ||
      document.referrer.includes('tiktok') ||
      document.referrer.includes('snapchat') ||
      document.referrer.includes('instagram') ||
      document.referrer.includes('facebook') ||
      document.referrer.includes('youtube') ||
      document.referrer.includes('linkedin')
    )
  ) {
    sessionStorageFn()
  } else if (
    !utmSource &&
    (document.referrer.includes('twitter') ||
      document.referrer.includes('tiktok') ||
      document.referrer.includes('snapchat') ||
      document.referrer.includes('instagram') ||
      document.referrer.includes('facebook') ||
      document.referrer.includes('youtube') ||
      document.referrer.includes('linkedin'))
  ) {
    sessionStorageFn()
  } else if (!document.referrer && !utmSource) {
    sessionStorageFn()
  }
  sessionStorage.setItem('cstmCmpgn', searchParams?.utm_campaign)
  return searchParams?.utm_campaign
}

export const getCustomContent = (searchParams) => {
  const utmSource = searchParams?.utm_source ? searchParams?.utm_source.toLowerCase() : null

  if (
    document.referrer &&
    !(
      document.referrer.includes('twitter') ||
      document.referrer.includes('tiktok') ||
      document.referrer.includes('snapchat') ||
      document.referrer.includes('instagram') ||
      document.referrer.includes('facebook') ||
      document.referrer.includes('youtube') ||
      document.referrer.includes('linkedin')
    )
  ) {
    cstmCntntStorage()
  } else if (
    !utmSource &&
    (document.referrer.includes('twitter') ||
      document.referrer.includes('tiktok') ||
      document.referrer.includes('snapchat') ||
      document.referrer.includes('instagram') ||
      document.referrer.includes('facebook') ||
      document.referrer.includes('youtube') ||
      document.referrer.includes('linkedin'))
  ) {
    cstmCntntStorage()
  }

  if (
    document.referrer &&
    !(
      document.referrer.includes('twitter') ||
      document.referrer.includes('snapchat') ||
      document.referrer.includes('instagram') ||
      document.referrer.includes('facebook') ||
      document.referrer.includes('youtube') ||
      document.referrer.includes('linkedin')
    )
  ) {
    cstmCntntStorage()
  } else if (
    !utmSource &&
    (document.referrer.includes('twitter') ||
      document.referrer.includes('snapchat') ||
      document.referrer.includes('instagram') ||
      document.referrer.includes('facebook') ||
      document.referrer.includes('youtube') ||
      document.referrer.includes('linkedin'))
  ) {
    cstmCntntStorage()
  } else if (!document.referrer && !utmSource) {
    cstmCntntStorage()
  }
  sessionStorage.setItem('cstmCntnt', searchParams?.utm_content)
  return searchParams?.utm_content
}

export const mixpanelTrackPageview = (details, queryParams) => {
  const searchParams = queryParams
  const dataDetails = {
    ...details,
    marketing_channel_grouping: sessionStorage.getItem('marketingChnnlGrp')? sessionStorage.getItem('marketingChnnlGrp'): marketingChannelGrouping(searchParams),
    custom_source: sessionStorage.getItem('cstmSrc')? sessionStorage.getItem('cstmSrc'):getCustomSource(searchParams),
    custom_medium: sessionStorage.getItem('cstmMed')? sessionStorage.getItem('cstmMed'):customMedium(searchParams) || searchParams?.utm_medium,
    custom_campaign: sessionStorage.getItem('cstmCmpgn')? sessionStorage.getItem('cstmCmpgn'):getCustomCampaign(searchParams) ,
    custom_content: sessionStorage.getItem('cstmCntnt')? sessionStorage.getItem('cstmCntnt'):getCustomContent(searchParams),
  }
  // if (process.env.NEXT_PUBLIC_IS_PRODUCTION == 'true' || process.env.NEXT_PUBLIC_IS_PREPROD == 'true')
    mixpanel.track('Page View', dataDetails)
}
